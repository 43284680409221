import * as Sentry from "@sentry/react";
import { isProduction } from "./mode";
import { isProdUrl, whitelist } from "@/constants";
import { userId } from "./telegram";


let SentryObject: typeof Sentry | null = null;

if (isProduction || isProdUrl) {
  const urlData = new URLSearchParams(window.location.search);
  const _userId = userId ?? urlData.get("userId") ?? 0;

  Sentry.init({
    dsn: "https://9e85336fa0a3b0e2f66d92abe47af0bb@o4507328241270784.ingest.de.sentry.io/4507963013595216",
    integrations: [
      Sentry.captureConsoleIntegration({
        levels: ["error", "assert"],
      }),
    ],
    sampleRate: whitelist.includes(+_userId) ? 1 : 0.02,
  });

  Sentry.setTag("userId", _userId);

  Sentry.setContext("user", {
    id: _userId,
  });

  SentryObject = Sentry;
}

export default SentryObject;
