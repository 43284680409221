// QueryWrapper.tsx
import { Dialog } from "@/components/Dialog";
import walletBg from "@/assets/capybara-images/walletBg.png";
import iosWalletImg from "@/assets/capybara-images/wallet.png";
import { openExternalUrl } from "@/utils/handleExpandPage";

export type SignType = {
  ok: boolean;
  signature: string;
  random_id: string;
  valid_until: number;
  balance: number;
  league: number;
  wallet: string;
};

interface QueryWrapperProps {
  isOpen: boolean;
  handleClose: () => void;
  status: number;
}

export const BuyMoneyBagDialog = ({
  isOpen,
  handleClose,
  status,
}: QueryWrapperProps) => {
  if (!isOpen) return null;

  switch (status) {
    case 410:
      return (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={true}
          onClose={() => {
            handleClose();
          }}
          onAction={() => {
            openExternalUrl("https://tradeport.xyz");
            handleClose();
          }}
          handleAdditionalAction={() => {
            openExternalUrl(
              "https://capybaraonsui.notion.site/3-How-to-buy-Money-Bag-NFT-559b3b29f9c840e58bdf25c0f549f837"
            );
            handleClose();
          }}
          title={"Minting Unavailable"}
          text={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <p>
                Your account isn't eligible to mint Money Bags. Eligibility is
                based on feeding your Capybara regularly, completing tasks, and
                inviting friends to join.
              </p>
              <p>
                In some cases, accounts may also be restricted due to suspicious
                activity.
              </p>
              <p>
                You can still purchase Money Bags from other users on the
                marketplace.
              </p>
              <p>
                Thank you for your understanding as we maintain fairness and
                reward active participation.
              </p>
            </span>
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Buy Money Bag"}
          additionalbuttonText={"How to Buy User Guide"}
        />
      );
    case 424:
      return (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={true}
          onClose={() => {
            handleClose();
          }}
          onAction={() => {
            handleClose();
          }}
          title={"🚨 That’s all for today! 🚨"}
          text={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <p>
                You’ve reached your daily limit of 10 Money Bags! 🎉💰 But don’t
                miss out—come back tomorrow to open more and boost your balance
                for the BIG EVENT reward! ⏳✨
              </p>
              <p>
                ⚠️ Important: To qualify for the BIG EVENT reward, you MUST mint
                a Player Card NFT. Your reward will be based on the balance
                recorded in your Player Card, so keep it updated to make every
                coin count! ✅💰
              </p>
              <p>Stay on track and don’t miss your chance! 🚀</p>
            </span>
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Return to Telegram"}
        />
      );
    case 423:
      return (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={true}
          onClose={() => {
            handleClose();
          }}
          onAction={() => {
            handleClose();
          }}
          title={"🚨 Limit Reached! 🚨"}
          text={
            <span
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "12px",
              }}
            >
              <p>
                You’ve reached the maximum number of Money Bags that can be
                opened per user! 🎉💰
              </p>
              <p>
                Make sure to update your Player Card NFT to lock in your final
                balance for the BIG EVENT! ✅✨
              </p>
            </span>
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Return to Telegram"}
        />
      );
    default:
      return (
        <Dialog
          modalWallpaper={walletBg}
          isOpen={true}
          onClose={() => {
            handleClose();
          }}
          onAction={() => {
            handleClose();
          }}
          title={"Something went wrong"}
          text={
            "Looks like something went wrong with minting your money bag. Status:" +
            status
          }
          modalTitleColor={"#C0C3FF"}
          modalIcon={iosWalletImg}
          buttonText={"Return to Telegram"}
        />
      );
  }
};
